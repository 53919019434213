import ky, { NormalizedOptions } from 'ky'
import { isForceLogoutResponse } from 'src/auth/keycloak'
import { ENV, isClient, isServer } from 'src/utils/env'
import { logger } from 'src/utils/logger'
import { isAuthRoute } from 'src/utils/navigation'
import { getWindowNextDataProps, getWindowNextDataQuery } from 'src/utils/get-window-next-data'
import handleRefreshTokenIfExpired from 'src/api/utils/handleRefreshTokenIfExpired'

const { token, slug, installationId } = getWindowNextDataProps()

const { userIdpId: queryUserId } = getWindowNextDataQuery() // TODO: [AGB-6094] Should this be userIdpId or userId, see src/api/payments-service/client.ts

type CentreClientFactoryArgs = {
  slug?: string
  token?: string | null
  installationId?: string
  queryUserId?: string
}

export const centreClientFactory = ({
  slug,
  token,
  installationId,
  queryUserId,
}: CentreClientFactoryArgs) =>
  ky.create({
    prefixUrl: ENV.CENTRE_API,
    retry: 0,
    timeout: 60 * 1000, // 1 min
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'App-Name': ['bushel-web-portal', ENV.BUSHEL_ENVIRONMENT].join('-'),
      'App-Company': slug ?? undefined,
      'App-Version': ENV.APP_VERSION,
      'App-Installation-Id': installationId ?? undefined,
      'X-Impersonate-Id': queryUserId,
    },
    // Do not use `afterResponse` on the server due to node-fetch bug
    hooks: isServer()
      ? {}
      : {
          beforeRequest: [handleRefreshTokenIfExpired],
          afterResponse: [
            async (request, options, response) => {
              if (!response.ok) {
                response = await handleFailedRequest(request, options, response)
              }

              if (response.status === 403) {
                return handleNotAuthorized(request, options, response)
              }

              return response
            },
          ],
        },
  })

export let centreClient = (function () {
  return centreClientFactory({ slug, token, installationId: installationId as string, queryUserId })
})()

// AFTER RESPONSE HANDLERS
///////////////////////////////////////////////////////////////////////////////////////////////////

export async function handleFailedRequest(
  request: Request,
  _options: NormalizedOptions,
  response: Response
): Promise<Response> {
  try {
    const body = await response.text()

    console.error(
      `src/api - api request failed [response.url: ${response?.url}][response.status: ${response?.status}][body: ${body}]`,
      {
        url: response.url,
        status: response.status,
        requestUrl: request.url,
        body,
      }
    )

    response.payload = JSON.parse(body)
  } catch (err) {
    console.error('src/api - api request failed (exception)', {
      url: response.url,
      status: response.status,
      message: err.message,
    })
  }

  return response
}

// Semaphore for avoiding duplicate/overlapping force-logout handling
let LAST_FORCE_LOGOUT_TIME: Date | null = null

export async function handleNotAuthorized(
  _request: Request,
  _options: NormalizedOptions,
  response: Response
) {
  logger.warn({ message: 'src/api - afterResponse received 403' })

  if (await isForceLogoutResponse(response)) {
    // Simultaneous requests may encounter this error, but this handling only needs to happen once
    if (shouldSkipForceLogoutHandling()) {
      logger.warn({
        message: `src/api - skipping force logout, recently handled at ${LAST_FORCE_LOGOUT_TIME?.toISOString()}`,
      })
    } else {
      LAST_FORCE_LOGOUT_TIME = new Date()

      logger.warn({ message: 'src/api - received 403 force log out response' })

      if (isClient() && !isAuthRoute()) {
        window.location.replace('/auth/logout')
      }
    }
  }

  return response
}

const FORCE_LOGOUT_MIN_OVERLAP_SECS = 10
function shouldSkipForceLogoutHandling() {
  // Never happened - no skip
  if (!LAST_FORCE_LOGOUT_TIME) return false

  // The `/auth/{login,logout}` routes handle this situation on their own - skip
  if (isAuthRoute()) return true

  const secondsSinceLastForceLogout =
    (new Date().getTime() - LAST_FORCE_LOGOUT_TIME.getTime()) / 1000

  // If we're under the minimum threshold for re-handling this - skip
  return secondsSinceLastForceLogout < FORCE_LOGOUT_MIN_OVERLAP_SECS
}

// UTILS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const updateCentreClient = (config = {}) => {
  centreClient = centreClient.extend(config)
}

export const updateCentreToken = (token: string | null | undefined) =>
  updateCentreClient({ headers: { Authorization: token ? `Bearer ${token}` : undefined } })

export const updateCentreAppHeaders = ({
  slug,
  token,
  installationId,
  selectedUserId = undefined,
}: {
  slug: string
  token: string | null | undefined
  installationId: string
  selectedUserId?: string
}) => {
  updateCentreClient({
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'App-Company': slug,
      'App-Installation-Id': installationId,
      'App-Name': ['bushel-web-portal', ENV.BUSHEL_ENVIRONMENT].join('-'),
      'App-Version': ENV.APP_VERSION,
      'X-Impersonate-Id': selectedUserId ?? undefined,
    },
  })
}

export const clearCentreAppHeaderImpersonation = () => {
  updateCentreClient({
    headers: {
      'X-Impersonate-Id': undefined,
    },
  })
}

export const updateCentreAppHeader = (key: string, value: string) => {
  updateCentreClient({
    headers: { [key]: value },
  })
}
